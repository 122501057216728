import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBottomSheet.figmaUrl.ios} codeUrl={checklists.componentBottomSheet.codeUrl.ios} checklists={checklists.componentBottomSheet.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The bottom sheet is a slides from the bottom of the screen
Whereas a bottom navigation component is for buttons and specific application level actions, a bottom sheet can contain anything.`}</p>
    <p>{`Use Legion button sheet styles to modify button sheet with lots of attributes that make you easier.`}</p>
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`struct ContentView: View {
  var body: some View {
    VStack {
      // screen content
    }.lgnBottomSheet(isShowing: .constant(true)) {
      // sheet content
    }
  }
}

// or
BottomSheet(isShowing: .constant(true)) {
  // sheet content
}
`}</code></pre>
    <h3>{`Usage Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`struct ContentView: View {
  var body: some View {
    VStack {
      // screen content
    }.lgnBottomSheet(isShowing: .constant(true)) {
      // sheet content
    }
  }
}

// or
LGNBottomSheet(isShowing: .constant(true)) {
  // sheet content
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion Have 2 Variant of Buttom Sheet :`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fd4258ba0-12e5-4a0d-90b6-ef70ccf3b057%2FScreen_Shot_2022-12-28_at_9.42.26_PM.png?id=dc9f071e-31f6-41fe-a5b9-113afdb9954a&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=2000&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`lgnBottomSheet`}</inlineCode>{` and add `}<inlineCode parentName="p">{`dragable: false`}</inlineCode>{` for Without Dragger`}</p>
    <p>{`This code sample demonstrates how to modify the type of button sheet :`}</p>
    <p>{`A. With Dragger`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var isShowing: Bool = false
var body: some View {
    content()
        .BottomSheet(isShowing: $isShowing) {

        }
}
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var isShowing: Bool = false
var body: some View {
    content()
        .lgnBottomSheet(isShowing: $isShowing) {

        }
}
`}</code></pre>
    <p>{`B. Without Dragger`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var isShowing: Bool = false
var body: some View {
    content()
        BottomSheet(isShowing: $isShowing,
                        dragable: false) {

        }
}
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var isShowing: Bool = false
var body: some View {
    content()
        .lgnBottomSheet(isShowing: $isShowing,
                        dragable: false) {

        }
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 2 Attributes for Customize Bottom Sheet :`}</p>
    <h3><strong parentName="h3">{` Header `}</strong></h3>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Title`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe5dab16e-639c-4bb5-bbc9-38e4b3ccf081%2FUntitled.png?id=31e4b38e-abcb-47cd-b225-d0c934cb06c6&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=650&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Title & Icon`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F63f1071a-b422-4b4a-bd73-1a2f6b13addf%2FUntitled.png?id=64706d11-73a7-4cc1-8ffd-ed7fbb661dd8&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=640&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Title, Icon & Desc`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F4272ed9f-8ac0-4075-8997-120b04567fec%2FUntitled.png?id=e1cea22a-b66f-4208-8fae-b8a7bc0d1e8d&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=650&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Title, Icon, Desc & Close Button`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fccc31ae1-bdc0-4689-b7a3-63ae3efa66c6%2FUntitled.png?id=28e21e86-4fa4-4e76-a01d-3dd20bb520eb&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=650&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`This code sample demonstrates how to modify header :`}</p>
    <p>{`A. Title`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`HeaderBottomSheet(title: "Title")
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNHeaderBottomSheet(title: "Title")
`}</code></pre>
    <p>{`B. Title & Icon`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`HeaderBottomSheet(
    icon: Image(systemName: "arrow.up.circle"),
    title: "Title"
)
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNHeaderBottomSheet(
    icon: Image(systemName: "arrow.up.circle"),
    title: "Title"
)
`}</code></pre>
    <p>{`C. Title, Icon & Desc`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`HeaderBottomSheet(
    icon: Image(systemName: "arrow.up.circle"),
    title: "Title",
    description: "description"
)
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNHeaderBottomSheet(
    icon: Image(systemName: "arrow.up.circle"),
    title: "Title",
    description: "description"
)
`}</code></pre>
    <p>{`D. Title, Icon, Desc & Close Button`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`HeaderBottomSheet(
    icon: Image(systemName: "arrow.up.circle"),
    title: "Title",
    showClose: true,
    description: "description"
)
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNHeaderBottomSheet(
    icon: Image(systemName: "arrow.up.circle"),
    title: "Title",
    showClose: true,
    description: "description"
)
`}</code></pre>
    <h3><strong parentName="h3">{` Button Position `}</strong></h3>
    <p>{`This code sample demonstrates how to modify button position :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Vertical`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F406c23b1-e391-4bb6-9282-cd49f27f2cf7%2FUntitled.png?id=31cfd339-02e0-46fb-b7bc-3b033bb80b90&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=610&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Horizontal`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F0fef52bf-988e-45cc-a58f-c3d8829c0967%2FUntitled.png?id=6fc6668d-0700-469d-895c-94c87517e287&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=650&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`LGNVButton`}</inlineCode>{` for vertical & `}<inlineCode parentName="p">{`LGNHButton`}</inlineCode>{` for horizontal`}</p>
    <p>{`A. Vertical`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`VButton(
    firstTitleButton: "first button",
    secondTitleButton: "second button"
)
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNVButton(
    firstTitleButton: "first button",
    secondTitleButton: "second button"
)
`}</code></pre>
    <p>{`B. Horizontal`}</p>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`HButton(
    firstTitleButton: "yes",
    secondTitleButton: "no",
    thirdTitleButton: "ok"
)
`}</code></pre>
    <p>{`Theme (ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNHButton(
    firstTitleButton: "yes",
    secondTitleButton: "no",
    thirdTitleButton: "ok"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`firstTitleButton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`show LGNSolidBtn with title.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`secondTitleButton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`show the LGNSoftBtn with title, and the button position is on the left firstButton.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`thirdTitleButton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`show the LGNTransparentBtn with title, and the button position is on the left secondButton.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`firstAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user triggers first button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`secondAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user triggers second button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`thirdAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user triggers third button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      